.AdminList {

  &__header {
    display: flex;
    flex-grow: 1;
    align-items: center;
  }

  &__title {

  }

  &__filters {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }

  &__filter-group {
    margin: 0 5px;
    background-color: rgba(black, 0.05);
    padding-left: 15px;
    border-radius: 10px;

    .MuiFormControlLabel-label {
      font-size: 14px;
      margin-left: -4px;
      user-select: none;
    }
  }

  &__actions {
    button {
      width: auto;
      box-shadow: none;
    }
  }

  &__pagination {
    padding: 30px;
    text-align: center;

    button {
      width: auto;
      white-space: nowrap;

      &:disabled:not(.AdminList__pagination-count) {
        opacity: 0.2;
      }
    }
  }
}
