/* ==========================================================================
  Variables
========================================================================== */

$font: "canada-type-gibson",sans-serif;
$headerHeight: 66;
$brandedHeaderHeight: 96;

$dark_grey: #252525;
$safety_orange: #FF9800;
$venetian_red: #C10811;
$caribbean_green: #00D181;
