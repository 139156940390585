.AdminLogin {
  text-align: center;
  padding: 30px 10px 10px 10px;

  &__panel {
    padding: 20px;
    margin: 0 auto;
  }

  &__error {
    color: red;
    font-size: 16px;
  }

  &__form {
    display: block;
    padding: 0;
    margin: 0;
  }

  &__form &__button {
    margin-top: 10px;
  }
}
