.AdminHeader {
  flex-grow: 1;

  &__grow {
    flex-grow: 1;
  }

  &__user {
  }
}
