/* ==========================================================================
  @imports
========================================================================== */

@import 'variables';
@import 'mixins';

/* ==========================================================================
  General styles
========================================================================== */
html {
  height: 100%;
}

body {
  @include font_regular;
  margin: 0;
  padding: 0;
  background-color: $dark_grey;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  min-height: 100%;
}

.no-scroll {
  overflow: hidden;
}

.grey-bg {
  background-color: $dark_grey;
}

a {
  text-decoration: none;
}

h3 {
  @include font_regular;
  font-size: 20px;
}

.route-wrapper {
  position: relative;
  height: 100%;
}

.route-wrapper > div {
  position: absolute;
  width: 100vw;
}

.fullscreen {
  height: 100vh;
  width: 100vw !important;
}

.padded {
  padding: 10px;
}

.white-text {
  color: #FFF;
}

.white-bg {
  padding: 20px 30px;
}

.center-text {
  text-align: center;
}

.logo {
  @include background-image;
  display: block;
  height: 12vh;
  width: 100%;
  max-width: 254px;
  background-image: url("../images/abi_logo.png");// TODO: Replace with @2x
  background-size: 40%;
}

.ribbon {
  @include colour_gradient;
  display: block;
  width: 100vw;
  height: 10px;
}

/* ==========================================================================
  Material UI Overrides
========================================================================== */

.MuiContainer-root {
  min-height: calc(100vh - #{$headerHeight}px);
  overflow: hidden;
}

.MuiButton-label {
  @include font_semibold;
}

.MuiButton-contained {
  background-color: #FFF !important;
  color: #000;
}

.MuiSnackbarContent-action {
  padding-left: 0 !important;
}


/* ==========================================================================
  Login
========================================================================== */

.login-header {
  width: 100vw;
  height: 12vh;
  background-color: #FFF;
}

.login-cover {
  @include background-image;
  position: relative;
  width: 100vw;
  height: 70vh;
  background-image: url("../images/abi_cover.png");
}

.login-details {
  position: absolute;
  width: 90vw;
  bottom: 10%;
  left: 5vw;
  z-index: 5;
}


/* ==========================================================================
  Header
========================================================================== */

// header {
//   .center-text {
//     width: calc(100% - 48px - 16px);
//   }
// }

.MuiPaper-elevation4 {
  box-shadow: none;
}

#popper {
  top: 20px !important;
  left: -6px !important;
  a {
    @include round_corners;
    display: block;
    color: #000;
    background: #FFF;
    padding: 12px 50px 12px 12px;
  }
}


/* ==========================================================================
  Toast / Snackbar
========================================================================== */

.MuiSnackbar-root {
  bottom: 20vh !important;
  .MuiSvgIcon-root {
    color: #FFF;
  }
}

.MuiSnackbarContent-message {
  width: 85%;
  font-size: 14px;
}

.toast-success {
  .MuiSnackbarContent-root {
    background-color: $caribbean_green;
  }
}

.toast-warning {
  .MuiSnackbarContent-root {
    background-color: $safety_orange;
  }
}

.toast-success,.toast-warning {
  .toast-message {
    display: inline-block;
    width: calc(90% - 20px);
    margin-left: 20px;
  }
}

.toast-action {
  display: block;
  margin-left: 42px;
  margin-top: 15px;
  text-align: center;

  a {
    display: inline-block;
    padding: 3px 8px;
    border: 2px solid currentColor;
    border-radius: 3px;
    text-transform: uppercase;
    color: white;
  }
}


/* ==========================================================================
  Camera
========================================================================== */

.camera_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  align-content: center;
  justify-content: center;
  overflow: hidden;
}

.camera-access {
  height: calc(100vh - #{$headerHeight}px);
}

.camera-intro {
  width: 90vw;
  margin: 0 auto;
}

.camera {
  position: relative;
  display: block;
  width: 100vw;
  height: calc(100vh - #{$headerHeight}px);
  object-fit: cover;
  z-index: 1;

  &--no-header {
    height: 100vh;
  }

  &--with-brand-header {
    height: calc(100vh - #{$brandedHeaderHeight}px);
  }
}

.overlay {
  position: absolute;
  width: 100vw;
  height: calc(100vh - #{$headerHeight}px);
  top: #{$headerHeight}px;
  z-index: 5;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &--no-header {
    height: 100vh;
    top: 0;
  }

  &--with-brand-header {
    height: calc(100vh - #{$brandedHeaderHeight}px);
    top: #{$brandedHeaderHeight}px;
  }
}

.canvas {
  position: relative;

  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }
}


/* ==========================================================================
  Admin Pages
========================================================================== */

.page-header {
  position: relative;
}

.btn {
  margin-left: 10px !important;
}

.rightBtns {
  position: absolute !important;
  right: 0;
  top: -10px;
}

.rightBtns, .MuiTableCell-root, form {
  button {
    box-shadow: none !important;
    width: auto;
  }
}

.nav-link {
  display: inline-block;
  color: #FFF;
  text-decoration: none;
  margin-right: 20px;
  opacity: .7;
  color: #000;
  &.selected, &:hover {
    opacity: 1;
    font-weight: bold;
  }
}

form {
  margin: 30px;
}

.field {
  display: block;
  width: 100%;
  margin: 10px 0 20px;
  padding: 5px;
  outline: 0;
  font-size: 14px;
}

// input[type="text"] {
//   border: 1px solid #DDD;
//   margin: 10px 0 30px;

//   &:focus, &:active {
//     border: 1px solid #333;
//   }
// }

th {
  text-transform: capitalize;
}
