/* ==========================================================================
  Mixins
========================================================================== */

/* Regular Italic */
@mixin font_italic {
  font-family: $font !important !important;
  font-weight: 400;
  font-style: italic;
}

/* Regular */
@mixin font_regular {
  font-family: $font !important;
  font-weight: 400;
  font-style: normal;
}

/* Semibold */
@mixin font_semibold {
  font-family: $font !important;
  font-weight: 600;
  font-style: normal;
}

/* Semibold Italic */
@mixin font_semibold_italic {
  font-family: $font !important;
  font-weight: 600;
  font-style: italic;
}

/* Book Italic */
@mixin font_book {
  font-family: $font !important;
  font-weight: 300;
  font-style: normal;
}

/* Book */
@mixin font_book_italic {
  font-family: $font !important;
  font-weight: 300;
  font-style: italic;
}


@mixin background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin colour_gradient {
  background: $venetian_red;
  background: linear-gradient(90deg, rgba($venetian_red,1) 0%, rgba($safety_orange,1) 100%);
}

@mixin round_corners {
  border-radius: 3px;
}
