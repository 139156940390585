.AccountForm {

  &__panel {
    position: relative;
    padding: 30px 30px 10px 30px;

    form {
      margin: 20px 0;
    }
  }

  &__panel &__close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 10;
  }

  &__panel &__submit {
    margin-top: 30px;
  }
}
