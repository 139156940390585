@import '../../assets/styles/variables';

.Header {
  height: 66px;
  background-color: white;
  color: #333333;
  z-index: 1;

  &.MuiAppBar-colorPrimary {
    background-color: white;
    color: #333333;
  }

  & {
    &--with-brand {
      height: #{$brandedHeaderHeight}px;
    }
  }

  &__logo {
    position: absolute;
    top: 8px;
    left: 50%;
    width: 160px;
    height: 40px;
    margin-left: -80px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &__title {
    text-align: center;
    padding-top: 8px;
    height: 40px;
    font-size: 16px;
    line-height: 1;
  }

  &__points {
    text-align: right;
    font-size: 18px;
    font-weight: bold;
    width: 90px;
    position: absolute;
    right: 16px;
  }
}
